import * as React from "react";
import {graphql, PageProps} from "gatsby"

import Layout from "../components/layout";
import Hero from '../components/hero';
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import TemplateHead from "../components/template-head";
import DirectorsFilter from "../components/directors-filter";

type DataProps = {
  strapiCity: any,
  strapiAdvisor: any,
  defaultHeroMobileBg: any
}

export default function FuneralDirectors({data: {strapiAdvisor, strapiCity, defaultHeroMobileBg}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--directors pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--directors"
        altImages="Fenix Funeral Directors"
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {name: 'Our Funeral Directors', slug: '/funeral-directors/'}]}
      >
      </Hero>
      <DirectorsFilter
        citiesList={strapiCity}
        advisorData={advisor}
        defaultCityBg={defaultHeroMobileBg}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head() {
  return (
    <TemplateHead
      title="Our Funeral Directors - Fenix Funeral"
      shortlink="https://fenixfuneral.co.uk/funeral-directors/"
      alternate="https://fenixfuneral.co.uk/funeral-directors/"
      canonical="https://fenixfuneral.co.uk/funeral-directors/"
      og={{
        url: "https://fenixfuneral.co.uk/funeral-directors/",
        title: "Our Funeral Directors - Fenix Funeral"
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": "1",
              "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
              "@type": "ListItem",
              "position": "2",
              "name": "Locations in UK","item": "https://fenixfuneral.co.uk/locations/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiCity: allStrapiCity(filter: {isHub: {eq: false}}, sort: {fields: title, order: ASC}) {
            nodes {
                strapi_id
                title
                cityUrl
                image {
                    middle {
                        localFile {
                            childImageSharp {
                                gatsbyImageData (
                                    height: 265, 
                                    width: 450,
                                    placeholder: NONE,
                                    outputPixelDensities: 1
                                )
                            }
                        }
                    }
                }
                hub {
                    urlPart
                }
                office {
                    fullAddress
                    postcode
                }
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400,
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
        defaultHeroMobileBg: allStrapiMedia(filter: {url: {eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_2x_4fc0811750.jpg"}}) {
            edges {
                node {
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
            }
        }
    }
`;