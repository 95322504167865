import * as React from "react";
import {useEffect, useState} from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import CtaHero from "./cta-card-hero";
import ClickReveal from "./click-reveal";

export default function DirectorsFilter(props: any) {

  const allCities = props.citiesList.nodes

  const [cities, setCities] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState(allCities);

  const [error, setError] = useState(null);

  useEffect(() => {

    setCities(allCities)

  }, []);

  const searchItems = (searchValue: any) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {

      const filteredData = allCities.filter((item: any) => {
        return item.title.toString().toLowerCase().indexOf(searchInput.toLowerCase()) > -1
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(cities);
    }
  };

  function handleChange(e: any) {
    searchItems(e.target.value);
  }

  return (
    <div className="filter-section filter-section--directors pb-5 pl-xl-4 pr-xl-4">
      <div className="filter-wrapper mb-0 pb-xl-5">
        <div
          className={"search-box d-flex flex-column-reverse flex-lg-row row no-gutters mb-0 mb-xl-4 pb-0 pb-xl-3 pl-4 pr-4 pl-xl-0 pr-xl-0"}>
          <div className="col-12 col-xl-8 pb-0 pr-xl-4 d-flex flex-column justify-content-end">
            <h1 className="hero-content__title text-white">Our Funeral Directors</h1>
            <div className="hero-content__subtitle mb-3 text-white">We provide a wide range of services throughout the
              United Kingdom to ensure every funeral is tailored to your needs and requirements. Whether you need
              transport, funeral planning or flowers arrangements, our services cover everything needed from start to
              finish.
            </div>
            <div className="row no-gutters">
              <div className="col-12 pb-lg-3 pb-xl-0">
                <input className="text-deepblue" id="search-input" type="text" onKeyUp={handleChange}
                       placeholder="search for your location here" value={searchInput} onChange={handleChange}/>
                <span></span>
              </div>
            </div>
          </div>
          {props.advisorData &&
            <div className="col-12 col-xl-4 d-flex flex-column justify-content-end">
              <CtaHero
                advisorData={props.advisorData}
              />
            </div>}
        </div>

        <div
          className="search-result search-result--city row no-gutters justify-content-between mt-4 pl-4 pr-4 pl-xl-0 pr-xl-0">
          {searchInput.length === 0 ? (cities.map((item: any, index: any) => {
              return (
                <div key={index} className={'tile-item'}>
                  <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                    <GatsbyImage
                      image={item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData ? item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData : props.defaultCityBg.edges[0]?.node?.localFile?.childImageSharp?.gatsbyImageData}
                      alt={item.title} loading="lazy"/>
                  </Link>
                  <div className="tile-content">
                    <div className="tile-item__title text-left d-flex flex-column">
                      <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                        <h3 className="text-center">{item.title}</h3>
                      </Link>
                      {item.office && item.office.fullAddress ? <p className="tile-item__line tile-item__address">
                        <span>{item.office.fullAddress + ", " + item.office.postcode}</span></p> : ""}
                      <p className="tile-item__line tile-item__phone">
                        <ClickReveal
                          className="ctr-button ctr-button--tile"
                        />
                      </p>
                      <p className="tile-item__line tile-item__email"><a
                        href="mailto:hello@fenixfuneral.co.uk"><span>hello@fenixfuneral.co.uk</span></a></p>
                      <p className="tile-item__line tile-item__clock d-flex flex-column">
                        <span>12:00 - 17:00</span><span>(only by online appointment)</span></p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchInput.length >= 1 && filteredResults?.length > 0 ? (filteredResults.map((item: any, index: any) => {
              return (
                <div key={index} className={'tile-item'}>
                  <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                    <GatsbyImage
                      image={item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData ? item.image?.middle?.localFile?.childImageSharp?.gatsbyImageData : props.defaultCityBg.edges[0]?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData} alt={item.title} loading="lazy"/>
                  </Link>
                  <div className="tile-content">
                    <div className="tile-item__title text-left d-flex flex-column">
                      <Link to={"/funeral-directors/" + item.hub.urlPart + "/" + item.cityUrl + "/"} title={item.title}>
                        <h3 className="text-center">{item.title}</h3>
                      </Link>
                      {item.office && item.office.fullAddress ? <p className="tile-item__line tile-item__address">
                        <span>{item.office.fullAddress + ", " + item.office.postcode}</span></p> : ""}
                      <p className="tile-item__line tile-item__phone">
                        <ClickReveal
                          className="ctr-button ctr-button--tile"
                        />
                      </p>
                      <p className="tile-item__line tile-item__email"><a
                        href="mailto:hello@fenixfuneral.co.uk"><span>hello@fenixfuneral.co.uk</span></a></p>
                      <p className="tile-item__line tile-item__clock d-flex flex-column">
                        <span>12:00 - 17:00</span><span>(only by online appointment)</span></p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : ("")}
        </div>
      </div>
    </div>
  );
}